import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { RouterStore } from 'mobx-router5';
import BaseRouteView from './components/BaseRouteView/BaseRouteView';
export interface IProps {
    routerStore: RouterStore;
}

@observer
export class App extends Component<{}>  {
   constructor() {
       super("");
      
   }

    render() {
        return (
            <div>
                <div>
                    <Fragment>
                        <BaseRouteView />
                    </Fragment>
                </div>
            </div>

        );
    }
}

export default inject('routerStore')(App);