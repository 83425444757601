import NotFoundPage from './pages/NotFound/NotFound';
import EnergyComponent from './pages/EnergyComponent/EnergyComponent';
import { constants } from 'router5';

export default [
    {
        name: 'EnergyComponent', 
        path: '/',
        component: EnergyComponent
    },    
    {
        name: constants.UNKNOWN_ROUTE,
        path: '/not-found',
        component: NotFoundPage
    }
];